import React from 'react';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';

export const TagItem = ({ name, count }: {
  name: string;
  count: number;
}): JSX.Element => (
  <div>
    <Link
      style={{ color: 'inherit' }}
      to={`/tags/${kebabCase(name)}/`}
    >
      <p className="has-text-grey is-size-7 my-3 has-text-weight-bold">{`${name} (${count})`}</p>
    </Link>
  </div>
);

const TagIcon = ({ name }: {
  name: string;
}): JSX.Element => (
  <div className="button is-small is-outlined">
    <Link
      style={{ color: 'inherit' }}
      to={`/tags/${kebabCase(name)}/`}
    >
      <p className="has-text-grey is-size-7 my-3 has-text-weight-bold">{name}</p>
    </Link>
  </div>
);

export const TagIcons = ({ tags }: { tags: string[] }): JSX.Element => (
  <div>
    {tags.map((tag) => (<TagIcon name={tag} />))}
  </div>
);
